import React from 'react'
import projectNames from '../src/configs/projectNames'
import { ThemeProvider as VeneerThemeProvider } from '@veneer/theme'
import { ThemeSettings } from '../src/types'
import { CountrySelector, GapHandler, LanguageSelector } from '../src'
import { CountrySelectorProps } from '../src/components/CountrySelector'
import { GapHandlerProps } from '../src/components/GapHandler'
import { LanguageSelectorProps } from '../src/components/LanguageSelector'

export default function Root(props: {
  properties: CountrySelectorProps &
    GapHandlerProps &
    LanguageSelectorProps & {
      themeSettings: ThemeSettings
      componentName: string
    }
}) {
  const component = () => {
    switch (props.properties.componentName) {
      case 'CountrySelector':
        return <CountrySelector {...props.properties} />
      case 'GapHandler':
        return <GapHandler {...props.properties} />
      case 'LanguageSelector':
        return <LanguageSelector {...props.properties} />
      default:
        return <h1>ComponentName not found</h1>
    }
  }

  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <VeneerThemeProvider
        customSemantics={props.properties?.themeSettings?.customSemantics}
        mode={props.properties?.themeSettings?.mode}
        shape={props.properties?.themeSettings?.shape}
        density={props.properties?.themeSettings?.density}
      >
        {component()}
      </VeneerThemeProvider>
    </section>
  )
}
