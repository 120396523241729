import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from '../../../hooks'
import * as Styled from './styles'
import {
  ButtonsContainer,
  LeftButton,
  RightButton
} from '../../CountrySelector/CountrySelectorModal/styles'
import LogoHp from '@veneer/core/dist/scripts/logos/hp/hp'
import Modal from '@veneer/core/dist/scripts/modal'
import { checkAndSetButtonsWrap, getCountryName } from '../../../helpers'
import { GapHandlerProps } from '../GapHandler'

export const GapHandlerModal = ({
  showGapHandlerModal,
  setShowGapHandlerModal,
  selectedLocale,
  accountLocale,
  onCreateNewAccount,
  onExitFlow,
  onContinueEnrollment,
  isUnsupportedCountry
}: GapHandlerProps) => {
  const { getText, changeTranslation, supportedLocales } = useTranslation()
  const [shouldWrapButtons, setShouldWrapButtons] = useState(false)
  const leftButtonRef = useRef(null)
  const rightButtonRef = useRef(null)
  const buttonsContainerRef = useRef(null)

  const translatedAccountLocale = () => {
    const countryCode = accountLocale.split('_')[1]
    const languageCode = selectedLocale.split('_')[0]
    return Object.prototype.hasOwnProperty.call(supportedLocales, accountLocale)
      ? getText(`locales.${accountLocale}.country`)
      : getCountryName(countryCode, languageCode)
  }

  const selectedCountryName = getText(`locales.${selectedLocale}.country`)
  const actualCountryName = translatedAccountLocale()

  useEffect(() => {
    const checkAndSetWrap = () => {
      const shouldWrap = checkAndSetButtonsWrap(
        rightButtonRef,
        leftButtonRef,
        buttonsContainerRef
      )
      setShouldWrapButtons(shouldWrap)
    }
    checkAndSetWrap()

    const handleResize = () => {
      checkAndSetWrap()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [showGapHandlerModal])

  useEffect(() => {
    changeTranslation(selectedLocale)
  }, [changeTranslation, selectedLocale])

  const texts = isUnsupportedCountry
    ? {
        first: getText('error_modal.cancel_or_new_account.body.text_01', {
          country_to_enroll: actualCountryName
        }),
        second: getText('error_modal.cancel_or_new_account.body.text_02'),
        leftButton: getText('shared.cancel_enroll_button'),
        rightButton: getText('shared.new_account_button')
      }
    : {
        first: getText('error_modal.enroll_or_new_account.body.text_01', {
          actual_country: actualCountryName,
          country_to_enroll: selectedCountryName
        }),
        second: getText('error_modal.enroll_or_new_account.body.text_02', {
          actual_country: actualCountryName
        }),
        leftButton: getText('shared.enroll_button', {
          actual_country: actualCountryName
        }),
        rightButton: getText('shared.new_account_button')
      }

  const handleOnClose = () => {
    if (isUnsupportedCountry) {
      setShowGapHandlerModal(false)
    } else {
      setShowGapHandlerModal(false)
      onContinueEnrollment()
    }
  }
  const handleCreateNewAccount = () => {
    onCreateNewAccount(selectedLocale)
  }

  const handleLeftButton = () => {
    if (isUnsupportedCountry) {
      onExitFlow()
    } else {
      onContinueEnrollment()
    }
  }

  return (
    <Modal
      show={showGapHandlerModal}
      onClose={handleOnClose}
      maxWidth={648}
      closeButton
      closeOnBlur={false}
      data-testid="gap-handler-modal"
    >
      <Styled.CountrySelectorGapHandlerContainer>
        <LogoHp appearance="singlecolor" size={64} />
        <Styled.CountrySelectorGapHandlerTitle>
          {getText('error_modal.title')}
        </Styled.CountrySelectorGapHandlerTitle>
        <Styled.CountrySelectorGapHandlerFirstDescription
          dangerouslySetInnerHTML={{ __html: texts.first }}
        />
        <Styled.CountrySelectorGapHandlerSecondDescription
          dangerouslySetInnerHTML={{ __html: texts.second }}
        />
        <ButtonsContainer
          ref={buttonsContainerRef}
          $shouldWrapButtons={shouldWrapButtons}
        >
          <LeftButton
            appearance="secondary"
            ref={leftButtonRef}
            onClick={handleLeftButton}
            $shouldWrapButtons={shouldWrapButtons}
          >
            {texts.leftButton}
          </LeftButton>
          <RightButton
            ref={rightButtonRef}
            onClick={handleCreateNewAccount}
            $shouldWrapButtons={shouldWrapButtons}
          >
            {texts.rightButton}
          </RightButton>
        </ButtonsContainer>
      </Styled.CountrySelectorGapHandlerContainer>
    </Modal>
  )
}
