import React, { useEffect, useState } from 'react'
import * as Styled from './styles'
import { useTranslation } from '../../../hooks'
import IconLanguage from '@veneer/core/dist/scripts/icons/icon_language'
import Card from '@veneer/core/dist/scripts/card'
import Select, {
  SelectOption,
  SelectOnChangeType
} from '@veneer/core/dist/scripts/select'
import { supportedLanguages } from '../../../helpers'
import { OptionObject } from '../../../types'
import { LanguageSelectorProps } from '../LanguageSelector'

export const LanguageSelectorCard = ({
  locale,
  onSaveLanguage
}: LanguageSelectorProps) => {
  const { getText, supportedLocales } = useTranslation()
  const [language, country] = locale.split('_')

  const languageOptions = supportedLanguages(locale, supportedLocales)

  const [languageList, setLanguageList] = useState(languageOptions[country])
  const [languageValue, setLanguageValue] = useState([language])

  const [selectedLanguage, setSelectedLanguage] = useState(language)

  useEffect(() => {
    setLanguageValue([selectedLanguage])
  }, [languageList, selectedLanguage])

  const handleChange = (event: OptionObject) => {
    const lang = event.value
    setSelectedLanguage(lang)
    const newLanguageList = supportedLanguages(
      `${lang}_${country}`,
      supportedLocales
    )
    setLanguageList(newLanguageList[country])
  }

  const handleUpdate = () => {
    onSaveLanguage(selectedLanguage)
  }

  const shouldDisableUpdateButton = selectedLanguage === language

  const languageSelectorContent = () => {
    return (
      <Styled.LanguageSelectorContent>
        <IconLanguage size={32} />
        <Styled.LanguageSelectorTitle>
          {getText('language_selector_card.title')}
        </Styled.LanguageSelectorTitle>
        <Styled.LanguageSelectorDescription>
          {getText('language_selector_card.description')}
        </Styled.LanguageSelectorDescription>
        <Styled.LanguageSelectorSelectContainer>
          <Select
            label={getText('country_selector_modal.language.select_label')}
            options={languageList as unknown as SelectOption<string>[]}
            value={languageValue}
            onChange={handleChange as SelectOnChangeType}
            clearIcon={false}
          />
        </Styled.LanguageSelectorSelectContainer>
        <Styled.LanguageSelectorButton
          disabled={shouldDisableUpdateButton}
          onClick={handleUpdate}
          data-testid="language-selector-update-button"
        >
          {getText('shared.update_button')}
        </Styled.LanguageSelectorButton>
      </Styled.LanguageSelectorContent>
    )
  }

  return (
    <Card
      background={'filled'}
      border={'dropShadow'}
      content={languageSelectorContent()}
      data-testid="language-selector-card"
    />
  )
}
