import styled from 'styled-components'
import Button from '@veneer/core/dist/scripts/button'

export const LanguageSelectorContent = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 32px;
  }
`

export const LanguageSelectorTitle = styled.h4`
  && {
    font-family: 'Forma DJR UI', serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    margin: 8px 0 4px;
  }
`

export const LanguageSelectorDescription = styled.span`
  && {
    font-family: 'Forma DJR UI', serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 16px;
  }
`

export const LanguageSelectorSelectContainer = styled.div`
  && {
    width: 100%;
    margin: 0 0 32px;
  }
`

export const LanguageSelectorButton = styled(Button)`
  && {
    padding: 14px 24px;
    align-self: flex-end;

    span {
      font-family: 'Forma DJR UI', serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
      white-space: normal;
    }
  }
`
