import React from 'react'
import { TranslationProvider } from '../../contexts/TranslationProvider'
import { LanguageSelectorCard } from './LanguageSelectorCard/LanguageSelectorCard'

export interface LanguageSelectorProps {
  /* locale: The locale of the account
   * Should be like 'en_US', 'fr_CA'
   * */
  locale: string

  /* onSaveLanguage: Function to save the selected language
   * Will be send the language code as parameter.
   * i.e. 'en', 'fr'
   * */
  onSaveLanguage: (language: string) => void
}

export const LanguageSelector = (props: LanguageSelectorProps) => {
  const [language, country] = props.locale.split('_')
  return (
    <TranslationProvider language={language} country={country}>
      <LanguageSelectorCard {...props} />
    </TranslationProvider>
  )
}

LanguageSelector.defaultProps = {
  locale: 'en_US',
  onSaveLanguage: () => null
}
