import React from 'react'

export const checkAndSetButtonsWrap = (
  rightButtonRef: React.MutableRefObject<HTMLElement>,
  leftButtonRef: React.MutableRefObject<HTMLElement>,
  buttonsContainerRef: React.MutableRefObject<HTMLElement>
) => {
  let shouldWrap = false
  const buttonsPadding = 40
  const buttonsMinWidth = 112
  const rightButtonLeftMargin = 16

  if (
    rightButtonRef.current &&
    leftButtonRef.current &&
    buttonsContainerRef.current
  ) {
    const rightButtonWidth = Math.max(
      (rightButtonRef.current.childNodes[0] as HTMLElement).offsetWidth +
        buttonsPadding,
      buttonsMinWidth
    )
    const leftButtonWidth = Math.max(
      (leftButtonRef.current.childNodes[0] as HTMLElement).offsetWidth +
        buttonsPadding,
      buttonsMinWidth
    )
    const containerWidth = buttonsContainerRef.current.offsetWidth

    shouldWrap =
      rightButtonWidth + leftButtonWidth + rightButtonLeftMargin >
      containerWidth
  }

  return shouldWrap
}
