import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const CountrySelectorGapHandlerContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: fit-content;
  }
`

export const CountrySelectorGapHandlerTitle = styled.h3`
  && {
    font-family: 'Forma DJR UI', serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    color: ${primitives.color.gray12};
    margin: 24px 0 20px;
  }
`

const CountrySelectorGapHandlerDescription = styled.p<{
  dangerouslySetInnerHTML: Record<string, unknown>
}>`
  && {
    font-family: 'Forma DJR UI', serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${primitives.color.gray12};
    margin-bottom: 40px;

    span {
      font-weight: bold;
      white-space: nowrap;
    }
  }
`

export const CountrySelectorGapHandlerFirstDescription = styled(
  CountrySelectorGapHandlerDescription
)`
  && {
    margin-bottom: 24px;
  }
`

export const CountrySelectorGapHandlerSecondDescription = styled(
  CountrySelectorGapHandlerDescription
)``
